<template>
  <v-chip
    :color="stateBackgroundColor"
    :text-color="stateTextColor"
    :small="small"
    class="text-uppercase"
  >
    {{ $t(`events.state.${state.toLowerCase()}`) }}
  </v-chip>
</template>

<script>
export default {
  name: "EventStateChip",
  props: {
    state: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    stateBackgroundColor: function() {
      switch (this.state.toLowerCase()) {
        case "scheduled":
          return "light-blue";
        case "active":
          return "cyan";
        case "inactive":
          return "error";
        case "live":
          return "success";
        case "finished":
          return "primary";
        default:
          return "";
      }
    },
    stateTextColor: function() {
      switch (this.state.toLowerCase()) {
        case "scheduled":
          return "white";
        case "active":
          return "white";
        case "inactive":
          return "white";
        case "live":
          return "white";
        case "finished":
          return "white";
        default:
          return "";
      }
    }
  }
};
</script>
