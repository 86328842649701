<template>
  <v-card>
    <v-dialog v-model="modal.show" persistent width="500">
      <v-card v-if="modal.error">
        <v-card-title class="error--text">
          {{ $t("hints.duplicateEvent.errors.title") }}
        </v-card-title>
        <v-card-text class="error--text">
          {{ modal.error }}
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          {{ $t("hints.duplicateEvent.title", { eventName: modal.eventName }) }}
        </v-card-title>
        <v-card-text>
          {{ $t("hints.duplicateEvent.text") }}

          <v-progress-linear indeterminate />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container>
      <v-row dense class="align-baseline">
        <v-col cols="12" sm="6">
          <v-btn :to="{ name: 'EventsCreate' }" color="success">
            {{ $t("events.createEvent") }} <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            name="search"
            :placeholder="$t('events.search')"
            v-model="form.search"
            clearable
            append-icon="mdi-magnify"
            @input="searchInput"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-card-title>{{ $t("events.title") }}</v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredEvents"
      :sort-by="['start']"
      :loading="loading"
      mobile-breakpoint="0"
      sort-desc
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <router-link :to="{ name: 'Live', params: { id: item.id } }" exact>
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.state="{ item }">
        <EventStateChip :state="item.state" small></EventStateChip>
      </template>
      <template v-slot:item.country="{ item }">
        <CountryFlag
          v-if="item.country"
          :country="getItemById(item.country)"
        ></CountryFlag>
      </template>
      <template v-slot:item.start="{ item }">
        {{ $d(new Date(item.start * 1000), "shortDate") }}
      </template>
      <template v-slot:item.end="{ item }">
        <span v-if="item.start !== item.end">
          {{ $d(new Date(item.end * 1000), "shortDate") }}
        </span>
        <span v-else>
          -
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-tooltip top :open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :to="{ name: 'Live', params: { id: item.id } }"
                icon
                exact
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            {{ $t("hints.showEvent") }}
          </v-tooltip>

          <v-tooltip top :open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :to="{ name: 'EventsEdit', params: { id: item.id } }"
                icon
                exact
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            {{ $t("hints.editEvent") }}
          </v-tooltip>

          <v-tooltip top :open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="duplicateEventHandler(item)" icon exact>
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            {{ $t("hints.duplicateEvent.tooltip") }}
          </v-tooltip>

          <v-tooltip top :open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="deleteEvent(item)" icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            {{ $t("hints.deleteEvent") }}
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-card-actions
      v-if="hasPrevPage || hasNextPage"
      class="d-flex justify-space-between"
    >
      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasPrevPage"
        @click="prevPage"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="grey--text text--darken-2 font-weight-bold">
        {{ currentPage + 1 }}
      </span>

      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasNextPage"
        @click="nextPage"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import EventStateChip from "@/components/EventStateChip";
import CountryFlag from "@/components/CountryFlag";

export default {
  name: "PanelEvents",
  components: {
    EventStateChip,
    CountryFlag
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      form: {
        search: null
      },
      loading: true,
      itemsPerPage: 10,
      currentPage: 0,
      modal: {
        show: false,
        eventName: null,
        error: null
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      events: state => state.events.itemsByUser
    }),
    ...mapGetters({
      getItemById: "nations/getItemById"
    }),
    headers() {
      return [
        {
          text: this.$i18n.t("events.table.name"),
          value: "name"
        },
        {
          text: this.$i18n.t("events.table.state"),
          value: "state"
        },
        {
          text: this.$i18n.t("events.table.place"),
          value: "place"
        },
        {
          text: this.$i18n.t("events.table.country"),
          value: "country"
        },
        {
          text: this.$i18n.t("events.table.startDate"),
          value: "start"
        },
        {
          text: this.$i18n.t("events.table.endDate"),
          value: "end"
        },
        {
          value: "actions",
          sortable: false,
          width: "1%"
        }
      ];
    },
    filteredEvents() {
      return this.events ? this.events.slice(0, this.itemsPerPage) : null;
    },
    hasPrevPage() {
      return this.currentPage !== 0;
    },
    hasNextPage() {
      return this.itemsPerPage < this.events.length;
    }
  },
  methods: {
    ...mapActions({
      deleteItem: "events/deleteItem",
      duplicateItem: "events/duplicateItem",
      fetchEvents: "events/fetchItemsByUser"
    }),
    duplicateEventHandler(event) {
      this.modal.eventName = event.name;
      this.modal.show = true;

      this.duplicateItem(event.id)
        .then(response => {
          this.modal.show = false;
          const newEvent = response.data.data[0];
          this.$router.push({
            name: "EventsEdit",
            params: { id: newEvent.id }
          });
        })
        .catch(response => {
          switch (response.data.status) {
            case -3009:
              this.modal.error = this.$i18n.t(
                "hints.duplicateEvent.errors.notEnoughTPs"
              );
              break;
            default:
              this.modal.error = this.$i18n.t(
                "hints.duplicateEvent.errors.failed"
              );
          }
          setTimeout(() => {
            this.modal.show = false;
          }, 3000);
        });
    },
    deleteEvent(event) {
      const payload = {
        userId: this.user.id,
        eventId: event.id
      };

      confirm(this.$i18n.t("events.confirmation", { event: event.name })) &&
        this.deleteItem(payload);
    },
    prevPage() {
      this.currentPage -= 1;
      this.fetchPage(this.form.search ? this.form.search : "");
    },
    nextPage() {
      this.currentPage += 1;
      this.fetchPage(this.form.search ? this.form.search : "");
    },
    searchInput: _.debounce(function(value) {
      this.currentPage = 0;
      this.fetchPage(value ? value : "");
    }, 250),
    fetchPage(search = "") {
      this.loading = true;

      const payload = {
        userId: this.user.id,
        filter: {
          offset: this.itemsPerPage * this.currentPage,
          limit: this.itemsPerPage + 1,
          search: search
        }
      };

      this.fetchEvents(payload)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.fetchPage();
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
</style>
